<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
    content-class="dialog-transition"
  >
    <v-card class="edit-post-card">
      <v-card-text class="pa-0 main-container" style="margin-bottom: 80px; overflow-y: auto;">
        <v-container fluid class="pa-4">
          <!-- 日期区域 -->
          <div class="module-wrapper">
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              location="bottom"
              :offset="0"
              min-width="auto"
            >
              <template v-slot:activator="{ props }">
                <div class="date-display white-module" v-bind="props">
                  <v-icon class="date-icon" size="22" color="#6BA1F3">mdi-calendar</v-icon>
                  <span class="date-text">{{ formatDate(date) }}</span>
                </div>
              </template>

              <v-date-picker
                v-model="date"
                @update:model-value="dateMenu = false"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </div>

          <!-- 图片和输入框合并的模块 -->
          <div class="module-wrapper">
            <div class="white-module">
              <!-- 图片区域 -->
              <div v-if="localImages.length > 0" class="image-container">
                <img 
                  :src="localImages[0].url" 
                  :class="['single-preview-img', { 'crop': singleImageAspectRatio < 3/4 }]" 
                  @load="onSingleImageLoad" 
                  ref="singleImage" 
                />
                <v-progress-circular
                  v-if="localImages[0].isUploading"
                  indeterminate
                  color="primary"
                  size="36"
                  class="image-upload-progress"
                ></v-progress-circular>
              </div>

              <!-- 文字输入区域 -->
              <v-textarea
                v-model="postContent"
                placeholder="这是在干嘛..."
                auto-grow
                rows="1"
                class="post-content"
                hide-details
                variant="plain"
                density="comfortable"
              ></v-textarea>
            </div>
          </div>

          <!-- 原来的两个模块合并成一个 -->
          <div class="module-wrapper">
            <div class="white-module">
              <div class="timeline-list">
                <!-- 已经发生的故事 -->
                <div v-if="pastStories.length">
                  <div class="section-title" v-if="pastStories.length">在这一天</div>
                  <div v-for="(story, index) in pastStories" :key="story.id" class="timeline-item">
                    <div class="timeline-content">
                      <span class="timeline-emoji">{{ story.emoji }}</span>
                      <div class="timeline-info">
                        <span class="timeline-name">{{ story.name }}</span>
                        <span class="timeline-time">{{ calculatePhotoTimeDiff(story.startDate) }}</span>
                      </div>
                    </div>
                    <!-- <v-btn 
                      icon 
                      density="comfortable"
                      variant="plain"
                      size="small"
                      class="remove-btn"
                      @click.stop="toggleEventSelection(getStoryIndex(story.id))"
                    >
                      <v-icon size="small">mdi-plus</v-icon>
                    </v-btn> -->
                  </div>
                </div>

                <!-- 还未发生的故 -->
                <div v-if="futureStories.length">
                  <div class="section-title" v-if="futureStories.length">即将发生</div>
                  <div v-for="(story, index) in futureStories" :key="story.id" class="timeline-item">
                    <div class="timeline-content">
                      <span class="timeline-emoji">{{ story.emoji }}</span>
                      <div class="timeline-info">
                        <span class="timeline-name">{{ story.name }}</span>
                        <span class="timeline-time">{{ calculatePhotoTimeDiff(story.startDate) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 没有故事时的提示 -->
                <div v-if="!selectedStories.length" class="no-stories">
                  <p>添加主角，让每张照片告诉你，主角当时几岁</p>
                  <v-btn
                    color="primary"
                    @click="showAddEventDialog = true"
                    class="add-story-btn"
                  >
                    添加主角
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-card-text>

      <!-- 底部操作 -->
      <div class="bottom-actions">
        <v-btn
          class="action-btn"
          color="primary"
          variant="outlined"
          @click="handleStoryClick"
        >
          我的主角
        </v-btn>
        <v-btn
          class="action-btn"
          color="primary"
          variant="outlined"
          @click="changeImage"
        >
          换张照片
        </v-btn>
        <v-btn
          class="action-btn"
          color="primary"
          @click="goToTimelime"
        >
          时间轴 
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
  
  <!-- 灵感库弹窗 -->
  <v-bottom-sheet v-model="inspirationDialog">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>灵感库</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="inspirationDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- 灵感库内 -->
        <p>这里是灵感库的内容...</p>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
  
  <!-- 悬浮按钮 -->
  <v-btn
    class="save-image-btn"
    color="primary"
    @click="saveAsImage"
  >
    保存为图片
  </v-btn>
  
  <!-- 修改添加事件弹窗 -->
  <v-dialog v-model="showAddEventDialog" max-width="400">
    <v-card class="add-event-card">
      <div class="emoji-selector" @click="showEmojiPicker = true">
        <span class="selected-emoji">{{ newEvent.emoji }}</span>
      </div>

      <v-card-text>
        <v-text-field
          v-model="newEvent.name"
          label="主角"
          type="text"
          variant="outlined"
          density="comfortable"
          hide-details
          class="mb-4"
        ></v-text-field>

        <v-text-field
          v-model="newEvent.startDate"
          label="开始时间"
          type="date"
          variant="outlined"
          density="comfortable"
          hide-details
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn 
          variant="text" 
          @click="showAddEventDialog = false"
          class="action-btn"
        >
          取消
        </v-btn>
        <v-btn 
          color="primary" 
          @click="addEvent"
          class="action-btn"
        >
          确定
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
  <!-- 在 template 中添加故事弹窗 -->
  <v-dialog
    v-model="storyDialog"
    :fullscreen="false"
    :scrim="true"
    transition="dialog-bottom-transition"
    content-class="story-dialog"
    @click:outside="closeStoryDialog"
    width="100%"
  >
    <v-card class="story-card">
      <v-toolbar flat class="story-toolbar">
        <v-toolbar-title class="text-h6">我的主角</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          class="new-story-btn"
          @click="showAddEventDialog = true"
        >
          新建
        </v-btn>
      </v-toolbar>

      <v-card-text class="story-content">
        <!-- 没有数据时显示提示文案 -->
        <div v-if="!storyData.length" class="story-description">
          添加主角，让每张照片告诉你，主角当时几岁
        </div>
        
        <!-- 有数据时显示故事列表 -->
        <div v-else class="story-list">
          <div 
            v-for="(story, index) in storyData" 
            :key="story.id"
            :class="['story-item', { 'story-item-selected': story.selected }]"
            class="story-item"
          >
            <div class="story-item-content" @click="toggleEventSelection(index)">
              <span class="story-emoji">{{ story.emoji }}</span>
              <div class="story-info">
                <div class="story-title-row">
                  <span class="story-name">{{ story.name }}</span>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        density="comfortable"
                        variant="plain"
                        size="small"
                        v-bind="props"
                        class="more-btn"
                        @click.stop
                      >
                        <v-icon size="small">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click.stop="editStory(index)">
                        <v-list-item-title>编辑</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click.stop="removeEvent(index)">
                        <v-list-item-title class="text-error">删除</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div class="story-dates">
                  <span class="story-label">开始：</span>
                  <span class="story-start-date">{{ formatStoryDate(story.startDate) }}-至今</span>
                </div>
                <div class="story-duration">
                  <span class="story-label">时间：</span>
                  <span class="story-label">{{ calculateCurrentTimeDiff(story.startDate) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- 在 template 中添加 Emoji 选择弹窗 -->
  <v-dialog v-model="showEmojiPicker" max-width="300" :scrim="false">
    <v-card class="emoji-picker-card">
      <v-card-text class="emoji-grid">
        <span
          v-for="emoji in availableEmojis"
          :key="emoji"
          class="emoji-item"
          @click="selectEmoji(emoji)"
        >
          {{ emoji }}
        </span>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Toast 提示 -->
  <v-snackbar v-model="showToast" :color="toastColor" :timeout="3000">
    {{ toastMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="showToast = false">
        关闭
      </v-btn>
    </template>
  </v-snackbar>
</template>
  
  <script setup>
  import { ref, computed, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { getTimeAgoString } from '@/utils/dateUtils';
  import storyApi from '@/services/storyApi';
  
  const store = useStore();
  
  const props = defineProps({
    images: {
      type: Array,
      default: () => []
    }
  });
  
  const dialog = ref(false);
  const postContent = ref('');
  const date = ref(new Date());
  const dateMenu = ref(false);
  
  const localImages = ref([...props.images]);

  const showToast = ref(false);
  const toastMessage = ref('');
  const toastColor = ref('success');
  
  // 获取故事开始时间
  const storyStartDate = computed(() => {
    const currentStory = store.getters['story/currentStory'];
    return currentStory ? new Date(currentStory.startTime) : new Date();
  });
  
  // 修改 timeAgoString 计算属性
  const timeAgoString = computed(() => {
    return getTimeAgoString(storyStartDate.value, date.value);
  });
  
  // 添加日期式化函数
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}年${month}月${day}日`;
  };

  const showToastMessage = (message, color = 'success') => {
      toastMessage.value = message;
      toastColor.value = color;
      showToast.value = true;
    };
  
  // 修改图片上传成功后的处理
  const uploadSingleImage = async (image) => {
    try {
      const response = await storyApi.uploadImage(image.file);
      if (response.result === 0 && response.body && response.body.list && response.body.list.length > 0) {
        const uploadedImage = response.body.list[0];
        const index = localImages.value.findIndex(img => img.url === image.url);
        if (index !== -1) {
          localImages.value[index] = {
            ...localImages.value[index],
            isUploading: false,
            uploadFailed: false,
            serverUrl: uploadedImage.pictureUrls, // 存服务器返回图片URL
            dateTime: uploadedImage.dateTime    // 保存服务器返回的时间
          };
          // 如果这是第一张图片，更新日期
          if (index === 0) {
            if (response.body.list[0].dateTime === null || response.body.list[0].dateTime === undefined){
              showToastMessage('照片拍摄日期已丢失', 'error');
              console.log("日期在哪里 ==== ",response.body.list[0].dateTime)
            }
            updateDate(uploadedImage.dateTime);
            
          }
        }
      } else {
        throw new Error('上传失败');
      }
    } catch (error) {
      console.error('上传图片出错:', error);
      const index = localImages.value.findIndex(img => img.url === image.url);
      if (index !== -1) {
        localImages.value[index] = {
          ...localImages.value[index],
          isUploading: false,
          uploadFailed: true
        };
      }
    }
  };
  
  const singleImageAspectRatio = ref(1);
  const singleImage = ref(null);
  
  const onSingleImageLoad = () => {
    if (singleImage.value) {
      const img = singleImage.value;
      singleImageAspectRatio.value = img.naturalWidth / img.naturalHeight;
    }
  };
  
  // 更期的函数
  const updateDate = (newDate) => {
    if (newDate) {
      date.value = new Date(newDate);
    } else {
      date.value = new Date();
    }
  };
  
  // 监控 date 的变化
  watch(date, () => {
    // date 变化时，timeAgoString 会重算
    console.log('发生时间更新，新的间差:', timeAgoString.value);
    // 触发重新计算 pastStories 和 futureStories
    pastStories.value = selectedStories.value.filter(story => new Date(story.startDate) <= new Date(date.value));
    futureStories.value = selectedStories.value.filter(story => new Date(story.startDate) > new Date(date.value));
  });
  
  // 监听 localImages 的变化
  watch(localImages, (newImages) => {
    if (newImages.length > 0 && newImages[0].dateTime) {
      updateDate(newImages[0].dateTime);
    } else if (newImages.length === 0) {
      updateDate(new Date());
    }
  }, { deep: true });
  
  const saveAsImage = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.crossOrigin = 'Anonymous'; // 处理跨域问题
    image.src = localImages.value[0].url; // 假存的第一张片

    image.onload = () => {
      // 设置 Canvas 尺寸
      canvas.width = 800; // 设定宽度
      canvas.height = 600; // 设定高度

      // 绘制背景
      ctx.fillStyle = '#ffffff'; // 背景颜色
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 绘制图片
      const imgWidth = 400;
      const imgHeight = 300;
      ctx.drawImage(image, 200, 150, imgWidth, imgHeight);

      // 加文
      ctx.fillStyle = '#000000'; // 文本颜色
      ctx.font = '24px Arial';
      ctx.fillText('欢迎吉喵到家', 300, 100); // 文本位置

      // 创建下载
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'downloaded-image.png';
      link.click();
    };
  };
  
  // 修改更换图片的方法
  const changeImage = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    
    input.onchange = async (event) => {
      const files = event.target.files;
      if (files && files[0]) {
        // 删除原有图片
        localImages.value = [];
        
        // 添加并上传新片
        const newImage = {
          url: URL.createObjectURL(files[0]),
          isUploading: true,
          uploadFailed: false,
          file: files[0]
        };
        
        localImages.value = [newImage];
        await uploadSingleImage(newImage);
      }
    };
    
    input.click();
  };

  // 去时间轴
  const goToTimelime = () => {
    showToastMessage('敬请期待', 'success');
  };
  
  onMounted(() => {
    console.log('Mounted EditPost, images:', localImages.value);
    
    // 初始化故事数据
    initStoryData();
    
    // 如果有传入的图片，立即开始上传
    if (localImages.value.length > 0) {
      localImages.value.forEach(uploadSingleImage);
    }
    
    // 如果没有图片设置默认日期为今天
    if (localImages.value.length === 0) {
      updateDate(new Date());
    } else if (localImages.value[0].dateTime) {
      // 如果有片，使用第一张图片的日期
      updateDate(localImages.value[0].dateTime);
    }
    
    setTimeout(() => {
      dialog.value = true;
    }, 0);
  });
  
  // 定义故事数据结构和操作方法
  const STORAGE_KEY = 'timeline_stories';
  const storyData = ref([]);
  const showAddEventDialog = ref(false);
  const showEmojiPicker = ref(false);
  const availableEmojis = ['🐱', '🐶', '🎂', '🎓', '💼', '💕', '🏠', '✈️', '🎮', '📚'];

  // 新事件的数结构
  const newEvent = ref({
    name: '',
    emoji: '🐱',
    startDate: new Date().toISOString().substr(0, 10)
  });

  // 初始化故事数据
  const initStoryData = () => {
    try {
      const savedData = localStorage.getItem(STORAGE_KEY);
      storyData.value = savedData ? JSON.parse(savedData).map(story => ({
        ...story,
        selected: true // 默认全部选中
      })) : [];
    } catch (error) {
      console.error('读取故事数据失败:', error);
      storyData.value = [];
    }
  };

  // 保存故事到本地
  const saveStoryToLocal = (story) => {
    try {
      storyData.value.push(story);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(storyData.value));
      return true;
    } catch (error) {
      console.error('保存故事失败:', error);
      return false;
    }
  };

  // 选择 emoji
  const selectEmoji = (emoji) => {
    newEvent.value.emoji = emoji;
    showEmojiPicker.value = false;
  };

  // 加故事
  const addEvent = () => {
    if (newEvent.value.name && newEvent.value.startDate) {
      const story = {
        id: Date.now(),
        emoji: newEvent.value.emoji,
        name: newEvent.value.name,
        startDate: newEvent.value.startDate,
        createTime: new Date().toISOString(),
        selected: true
      };

      if (saveStoryToLocal(story)) {
        showAddEventDialog.value = false;
        // 重置表单
        newEvent.value = {
          name: '',
          emoji: '🐱',
          startDate: new Date().toISOString().substr(0, 10)
        };
      }
    }
  };

  // 删除故事
  const removeEvent = (index) => {
    storyData.value.splice(index, 1);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storyData.value));
  };

  // 切换故事选中状态
  const toggleEventSelection = (index) => {
    storyData.value[index].selected = !storyData.value[index].selected;
  };

  // 添加故事日期格式化函数
  const formatStoryDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}年${month}月${day}日`;
  };

  // 计算照片时差的函数
  const calculatePhotoTimeDiff = (storyDate) => {
    const photoDate = new Date(date.value);
    const storyDateTime = new Date(storyDate);
    
    // console.log('计算时间差:', {
    //   storyDate: storyDateTime,
    //   photoDate: photoDate
    // });
    
    // 调用 getTimeAgoString 时，确保参数顺序正确
    // 第一个参是故事时间第二个参数是照时间
    return getTimeAgoString(storyDateTime, photoDate);
  };

  // 加故事日格式化函数
  const calculateCurrentTimeDiff = (startDate) => {
    const start = new Date(startDate);
    const now = new Date();
    return getTimeAgoString(start, now);
  };

  // 添加计算属性获取选中的故事
  const selectedStories = computed(() => {
    return storyData.value.filter(story => story.selected);
  });

  // 添加故事弹窗的状态控制
  const storyDialog = ref(false);

  // 添加处理故事按钮点击的方法
  const handleStoryClick = () => {
    storyDialog.value = true;
  };

  // 添加关闭故事弹窗的方法
  const closeStoryDialog = () => {
    storyDialog.value = false;
  };

  // 添加计算属性来区分已经发生和未发生的故事
  const pastStories = computed(() => {
    return selectedStories.value.filter(story => new Date(story.startDate) <= new Date(date.value));
  });

  const futureStories = computed(() => {
    return selectedStories.value.filter(story => new Date(story.startDate) > new Date(date.value));
  });
  </script>
  
  <style scoped>
  .edit-post-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .image-grid-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .image-grid {
    display: flex;
    flex-wrap: nowrap;
    padding: 8px 0;
  }
  
  .image-item {
    flex: 0 0 auto;
    width: 100px;
    height: 100px;
    margin-right: 8px;
    position: relative;
    overflow: hidden;
  }
  
  .preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image-action-btn {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding: 0 !important;
    transition: background-color 0.3s ease;
  }
  
  .image-action-btn:hover {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  
  /* 使用深度选择器来修改 Vuetify 组件的样式 */
  :deep(.v-text-field .v-input__control) {
    min-height: 40px;
  }
  
  :deep(.v-text-field .v-input__slot) {
    min-height: 40px;
  }
  
  .image-wrapper {
    padding: 20px;
    position: relative;
    margin-bottom: 16px; /* 添加与输入框的间距 */
  }
  
  .single-preview-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px; /* 添加圆角 */
  }
  
  .single-preview-img.crop {
    object-fit: cover;
    border-radius: 12px; /* 确保裁剪模式下也有圆角 */
  }
  
  .v-list {
    background-color: transparent !important;
  }
  
  .v-list-item {
    min-height: 48px;
    padding-left: 0;
    padding-right: 0;
  }
  
  .v-list-item__prepend {
    margin-right: 0;
  }
  
  .v-list-item__append {
    margin-left: 16px;
  }
  
  /* 调整时间显示样式 */
  .v-list-item-subtitle.text-caption {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  /* 修改过渡动画样 */
  :deep(.dialog-transition) {
    transition: transform 0.3s ease-in-out !important;
  }
  
  :deep(.dialog-bottom-transition-enter-active),
  :deep(.dialog-bottom-transition-leave-active) {
    transition: transform 0.3s ease-in-out !important;
  }
  
  :deep(.dialog-bottom-transition-enter-from),
  :deep(.dialog-bottom-transition-leave-to) {
    transform: translateY(100%) !important;
  }
  
  :deep(.dialog-bottom-transition-enter-to),
  :deep(.dialog-bottom-transition-leave-from) {
    transform: translateY(0) !important;
  }
  
  /* ... 其他样式保持不变 ... */
  
  .image-upload-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .retry-btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px !important;
    height: 36px !important;
    background-color: rgba(255, 0, 0, 0.7) !important;
  }
  
  .retry-btn:hover {
    background-color: rgba(255, 0, 0, 0.9) !important;
  }
  
  .save-image-btn {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1000;
    width: 120px; /* 设按钮宽度 */
    height: 48px; /* 设置按钮高度 */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px; /* 设置圆角 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 添加阴影 */
  }
  
  /* 修改更换图片按钮的样式 */
  .change-btn {
    display: none;
  }
  
  .event-list {
    margin-top: 8px;
  }
  
  .event-item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .event-date {
    color: var(--v-text-secondary);
    margin-left: 8px;
  }
  
  .date-display:hover {
    background-color: #F8F9FA !important;
  }
  
  .date-icon {
    margin-right: 8px;
  }
  
  .date-text {
    font-size: 22px !important;
    font-weight: bold !important;
    color: #6BA1F3 !important;
  }
  
  .edit-date-btn {
    margin-left: 8px;
    opacity: 0.6;
    width: 32px !important;
    height: 32px !important;
  }
  
  .edit-date-btn:hover {
    opacity: 1;
    background: transparent !important;
  }
  
  .edit-date-btn::before {
    display: none !important;
  }
  
  .content-divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
    margin: 16px 0;
  }
  
  .section-title {
    font-size: 14px;
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.6);
    padding: 0;
  }
  
  .timeline-list {
    display: flex;
    flex-direction: column;
    gap: 12px !important;
    margin-bottom: 0 !important;
  }
  
  .timeline-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px !important;
    background-color: #F8F9FA !important;
    border-radius: 12px;
    transition: background-color 0.3s;
    margin: 8px 0;
  }

  .timeline-item.selected {
    background-color: #E8F0FE !important;
    border: 1px solid #1A73E8 !important;
  }

  .timeline-content {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
  }
  
  .timeline-emoji {
    font-size: 20px !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  
  .timeline-name {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    margin-right: 12px !important;
  }
  
  .timeline-time {
    font-size: 13px !important;
    color: #1A73E8 !important;
    font-weight: 400 !important;
  }
  
  .selected {
    color: var(--v-primary);
  }
  
  .bottom-actions {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background-color: white !important;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) !important;
    z-index: 100 !important;
    padding: 12px 20px !important;
    display: flex !important;
    justify-content: space-between !important;
    gap: 12px !important;
  }
  
  .action-btn {
    height: 44px !important;
    font-weight: 500 !important;
    letter-spacing: 0.25px !important;
    text-transform: none !important;
    border-radius: 8px !important;
    padding: 0 20px !important;
  }
  
  .action-btn:nth-child(1),
  .action-btn:nth-child(2) {
    min-width: 100px !important;
    max-width: 100px !important;
    background-color: #F8F9FA !important;
    color: rgba(0, 0, 0, 0.87) !important;
    border: none !important;
  }
  
  .action-btn:nth-child(3) {
    min-width: 120px !important;
    max-width: 120px !important;
    background-color: #1A73E8 !important;
    color: white !important;
  }
  
  .action-btn:hover {
    opacity: 0.9 !important;
  }
  
  .remove-btn {
    opacity: 0.6;
    transform: rotate(45deg);
  }
  
  .remove-btn:hover {
    opacity: 1;
    background: transparent !important;
  }
  
  .remove-btn::before {
    display: none !important;
  }
  
  /* 修改故事弹窗样式 */
  :deep(.story-dialog) {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .story-card {
    position: relative;
    width: 100%;
    height: 60vh !important;
    max-height: 60vh !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  
  .story-toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    flex-shrink: 0;
  }
  
  .new-story-btn {
    font-weight: 500;
  }
  
  .story-content {
    padding: 24px;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .story-description {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    text-align: center;
  }

  .add-event-card {
    padding: 24px 0;
  }

  .emoji-selector {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .emoji-selector:hover {
    background-color: #eeeeee;
  }

  .selected-emoji {
    font-size: 32px;
  }

  .emoji-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    padding: 16px;
  }

  .emoji-item {
    font-size: 24px;
    padding: 8px;
    cursor: pointer;
    text-align: center;
    border-radius: 8px;
    transition: background-color 0.3s;
  }

  .emoji-item:hover {
    background-color: #f5f5f5;
  }

  .date-range {
    margin-top: 16px;
  }

  .action-btn {
    min-width: 120px;
  }

  /* 修改 Emoji 选择弹窗样式 */
  .emoji-picker-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
  }

  .story-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 0;
  }

  .story-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    transition: all 0.3s;
  }

  .story-item:hover {
    border-color: #e0e0e0;
    background-color: #fafafa;
  }

  .story-item-selected {
    background-color: #f5f5f5;
    border-color: transparent;
  }

  .story-item-selected:hover {
    background-color: #eeeeee;
    border-color: transparent;
  }

  .story-item-content {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
    cursor: pointer;
  }

  .story-emoji {
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }

  .story-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .story-name {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }

  .story-dates {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  .story-start-date {
    color: rgba(0, 0, 0, 0.6);
  }

  .story-duration {
    color: var(--v-primary-base);
    font-weight: 500;
  }

  .story-title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .story-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: normal;
  }

  .story-dates, .story-duration {
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgba(0, 0, 0, 0.6);
  }

  /* 添加下拉菜单的样式 */
  :deep(.v-menu > .v-overlay__content) {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  margin-top: 4px !important;
  z-index: 1000;
}

  :deep(.v-list) {
    background-color: white !important;  /* 修改为白色背景 */
    padding: 4px !important;
  }

  :deep(.v-list-item) {
    border-radius: 4px;
    min-height: 40px !important;
  }

  :deep(.v-list-item:hover) {
    background-color: #f5f5f5 !important;
  }

  :deep(.v-list-item__title) {
    font-size: 14px;
  }

  /* 调分割线的边距 */
  .content-divider {
    margin: 16px 0;  /* 统一上下边距 */
  }

  /* 调整"在这一天"标题的样式 */
  .section-title {
    font-size: 14px;
    margin-bottom: 12px;  /* 添加底部间距 */
    color: rgba(0, 0, 0, 0.6);  /* 调整颜色使其不那么突兀 */
  }

  /* 调整时间轴列表的边距 */
  .timeline-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;  /* 添加底部边距 */
  }

  /* 调整图片容器的边距 */
  .single-image-container {
    margin-bottom: 16px;  /* 添加底部边距 */
  }

  /* 调整底部操作栏的样式 */
  .bottom-actions {
    padding: 16px;
    background-color: white;
  }

  /* 调整文本编辑区域的样 */
  .post-content {
  font-size: 18px !important;
  /* color: rgba(0, 0, 0, 0.87) !important; */
  background: transparent !important;
  margin: 20px 0 0 0 !important; /* 添加顶部外边距20px */
}

:deep(.post-content) {
  .v-field__input,
  textarea {
    padding-top: 0 !important; /* 移除输入框的顶部内边距 */
    margin-top: 0 !important; /* 移除输入框的顶部外边距 */
  }
}



  /* 移除可能的背景遮罩 */
  :deep(.v-field__overlay) {
    display: none !important;
  }

  .white-module {
  background-color: white;
  border-radius: 12px;
  padding: 30px 20px !important;
  margin: 0 !important;
}

.date-display {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 15px 15px !important;
}

.date-icon {
  margin-right: 8px;
}


  /* 添加新的样式 */
  .main-container {
    background-color: #F3F2F6 !important;
    height: calc(100vh - 80px) !important; /* 减去底部操作栏的高度 */
    margin-bottom: 0 !important; /* 移除底部margin */
    overflow-y: auto !important;
    padding-bottom: 100px !important; /* 增加底部内边距，避免内容被底部操作栏遮挡 */

    /* 隐藏滚动条但保持可滚动 */
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari and Opera */
    }
  }

  /* 修改已有的样式 */
  .edit-post-card {
    display: flex;
    flex-direction: column;
    height: 100vh !important; /* 设置为全屏高度 */
    background-color: #F3F2F6 !important;
  }

  .timeline-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .timeline-item {
    background-color: transparent;  /* 移除原来的背景色 */
    padding: 12px 0;  /* 调整内边距 */
  }

  /* 确保底部操作栏样式正确 */
  .bottom-actions {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background-color: white !important;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100 !important;
    padding: 16px !important;
  }

  /* 修改文本框样式 */
  :deep(.v-text-field .v-field__outline) {
    border-radius: 12px !important;
  }

  .image-padding {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  /* 添加新的模块包装器样式 */
  .module-wrapper {
    margin-bottom: 10px !important; /* 统一模块间距 */
  }

  .module-wrapper:last-child {
    margin-bottom: 0;  /* 最后一个模块不需要底部间距 */
  }

  /* 移除其他可能影响间距的样式 */
  .v-row {
    margin: 0 !important; /* 确保 row 没有额外的 margin */
  }

  .v-col {
    padding: 0 !important; /* 确保 col 没有额外的 padding */
  }

  .image-container {
    position: relative;
    margin: -30px -20px;
    padding: 30px 20px 20px !important; /* 修改底部内边距为20px */
  }

  .single-preview-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
    box-shadow: none !important; /* 移除阴影 */
    display: block; /* 确保没有额外的间距 */
  }

  /* 如果还有阴影，可以添加以下样式 */
  .single-preview-img:not(.crop) {
    filter: none !important; /* 移除任何可能的滤镜效果 */
    -webkit-filter: none !important;
  }

  /* 确保容器本身也没有阴影 */
  .image-container img {
    box-shadow: none !important;
  }

  /* 移除文本框的白色蒙层和调整样式 */
  :deep(.v-field) {
    /* 移除蒙层 */
    .v-field__overlay {
      background-color: transparent !important;
      border-color: transparent !important;
      display: none !important;
    }

    /* 移除背景色 */
    .v-field__field {
      background-color: transparent !important;
    }

    /* 确保输入区域完全透明 */
    .v-field__input {
      background-color: transparent !important;
    }
  }


  /* 移除任何可能的背景色和阴影 */
  :deep(.v-text-field) {
    background: transparent !important;
    box-shadow: none !important;
  }

  /* 确保 v-container 也有足够的底部空间 */
  .v-container.fluid {
    padding: 16px !important;
    padding-bottom: 0px !important; /* 增加容器底部内边距 */
  }

  /* 修改模块间距 */
  .module-wrapper {
    margin-bottom: 10px !important; /* 统一模块间距 */
  }

  /* 修改时间轴列表样式 */
  .timeline-list {
    display: flex;
    flex-direction: column;
    gap: 12px !important;
    margin-bottom: 0 !important;
  }

  .timeline-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px !important;
    background-color: #F8F9FA !important;
    border-radius: 12px;
    transition: background-color 0.3s;
  }

  .timeline-content {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
  }

  .timeline-emoji {
    font-size: 20px !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .timeline-name {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    margin-right: 12px !important;
  }

  .timeline-time {
    font-size: 13px !important;
    color: #1A73E8 !important;
    font-weight: 400 !important;
  }

  .timeline-item.selected {
    background-color: #E8F0FE !important;
    border: 1px solid #1A73E8 !important;
  }

  .white-module {
    background-color: white;
    border-radius: 12px;
    padding: 20px !important;
    margin: 0 !important;
  }

  .section-title {
    font-size: 14px;
    margin-bottom: 12px !important;
    color: rgba(0, 0, 0, 0.6);
    padding: 0;
  }

  /* 修改日期选择器弹窗样式 */
  .date-picker-card {
    border-radius: 12px;
    overflow: hidden;
  }

  .date-picker-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px !important;
  }

  :deep(.v-text-field) {
    .v-field__input {
      font-size: 16px !important;
      padding: 8px 16px !important;
    }
    
    .v-field__outline {
      color: rgba(0, 0, 0, 0.12) !important;
    }
    
    &:hover .v-field__outline {
      color: rgba(0, 0, 0, 0.38) !important;
    }
    
    &--focused .v-field__outline {
      color: #1A73E8 !important;
    }
  }



  :deep(.date-picker) {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: none !important;
    
    .v-date-picker-header {
      padding: 16px 16px 0;
    }
    
    .v-date-picker-month {
      padding: 0 16px 16px;
    }
    
    .v-date-picker-table {
      height: auto;
    }
    
    .v-btn.v-btn--active {
      background-color: #1A73E8 !important;
      color: white !important;
    }
    
    .v-btn:not(.v-btn--active):hover {
      background-color: rgba(26, 115, 232, 0.1) !important;
    }
  }

  .no-stories {
    text-align: center;
    margin: 20px 0;
  }

  .add-story-btn {
    margin-top: 10px;
  }
  </style>